//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const Certificates: IWidget = {
  id: uuid(),
  name: 'Certificates',
  order: 0,
  category: 'Basic',
  open: false,
  label: {
    id: 'certificates',
    name: 'Certificates',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'CertificatesWidgetHeadline',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('WidgetHeadline_Certificates'),
          value: getValue('WidgetHeadline_Certificates'),
        },
        '2': {
          feId: 'ShowWidgetHeadline',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ShowWidgetHeadline'),
          value: getValue('ShowWidgetHeadline'),
        },
      },
    },
    {
      id: 'Naturland',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'CertificateName_Naturland',
          change: false,
          translate: true,
          data: false,
          valueType: 'value',
          name: getDesc('CertificateName_Naturland'),
          value: getValue('CertificateName_Naturland'),
        },
        '2': {
          feId: 'CertificatePicture_Naturland',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('CertificatePicture_Naturland'),
          value: getValue('CertificatePicture_Naturland'),
        },
        '3': {
          feId: 'CertificatePictureURL_Naturland',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('CertificatePictureURL_Naturland'),
          value: getValue('CertificatePictureURL_Naturland'),
        },
        '4': {
          feId: 'CertificateNumber_Naturland',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('CertificateNumber_Naturland'),
          value: getValue('CertificateNumber_Naturland'),
        },
        '5': {
          feId: 'Organization_Naturland',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('Organization_Naturland'),
          value: getValue('Organization_Naturland'),
        },
        '6': {
          feId: 'Website_Naturland',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('Website_Naturland'),
          value: getValue('Website_Naturland'),
        },
        '7': {
          feId: 'CertificationYear_Naturland',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('CertificationYear_Naturland'),
          value: getValue('CertificationYear_Naturland'),
        },
        '8': {
          feId: 'VerificationDocument_Naturland',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('VerificationDocument_Naturland'),
          value: getValue('VerificationDocument_Naturland'),
        },
        '9': {
          feId: 'CertificationCriteria_Naturland',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('CertificationCriteria_Naturland'),
          value: getValue('CertificationCriteria_Naturland'),
        },
        '10': {
          feId: 'CertificationProcess_Naturland',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('CertificationProcess_Naturland'),
          value: getValue('CertificationProcess_Naturland'),
        },
      },
    },
  ],
  style: {
    objects: {
      '1': {
        name: '',
        value: [''],
      },
    },
  },
};
