//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const HoverButton: IWidget = {
  id: uuid(),
  name: 'HoverButton',
  order: 0,
  category: '',
  open: false,
  label: {
    id: 'hoverButton',
    name: 'Hover Button',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'GoToTop',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('GoToTop'),
          value: getValue('GoToTop'),
        },
        '2': {
          feId: 'ShowContactMail',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ShowContactMail'),
          value: getValue('ShowContactMail'),
        },
        '3': {
          feId: 'ContactMail',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ContactMail'),
          value: getValue('ContactMail'),
        },
        '4': {
          feId: 'ShowRebuy',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ShowRebuy'),
          value: getValue('ShowRebuy'),
        },
        '5': {
          feId: 'HoverButtonProductURL',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('HoverButtonProductURL'),
          value: getValue('HoverButtonProductURL'),
        },
        '6': {
          feId: 'DiscountCode',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('DiscountCode'),
          value: getValue('DiscountCode'),
        },
        '7': {
          feId: 'DiscountCodeDescription',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('DiscountCodeDescription'),
          value: getValue('DiscountCodeDescription'),
        },
      },
    },
  ],
  style: {
    objects: {
      '1': {
        name: 'Orientation',
        value: ['right'],
      },
      '2': {
        name: 'ShowHover',
        value: ['X'],
      },
    },
  },
};
