import { ILangWidget } from 'src/types/product';

export const LangProductInfo: ILangWidget = [
  {
    feId: 'WidgetHeadline_ProductInformation',
    desc: [
      { lang: 'en', val: 'Widget Headline' },
      { lang: 'de', val: 'Product Information' },
    ],
    value: [
      { lang: 'en', val: ['Product Information'] },
      { lang: 'de', val: ['Produkt Information'] },
    ],
  },
  {
    feId: 'ShowWidgetHeadline',
    desc: [
      { lang: 'en', val: 'Show Widget Headline' },
      { lang: 'de', val: 'Show Widget Headline' },
    ],
    value: [
      { lang: 'en', val: ['X'] },
      { lang: 'de', val: ['X'] },
    ],
  },
  {
    feId: 'ProductInfoDescription',
    desc: [
      { lang: 'en', val: 'Description' },
      { lang: 'de', val: 'Description' },
    ],
    value: [
      { lang: 'en', val: ['Lorem ipsum'] },
      { lang: 'de', val: ['Lorem ipsum'] },
    ],
  },
  {
    feId: 'ProductInfoBasicInformation',
    desc: [
      { lang: 'en', val: 'Basic Information' },
      { lang: 'de', val: 'Basic Information' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'WidgetHeadline_Nutrients',
    desc: [
      { lang: 'en', val: 'Widget Headline' },
      { lang: 'de', val: 'Nutrients' },
    ],
    value: [
      { lang: 'en', val: ['Nutrients'] },
      { lang: 'de', val: ['Nährstoffe'] },
    ],
  },
  {
    feId: 'ProductInfoNutrients',
    desc: [
      { lang: 'en', val: 'Nutrients' },
      { lang: 'de', val: 'Nutrients' },
    ],
    value: [
      {
        lang: 'en',
        val: [
          { key: 'Energy', value: 0 },
          { key: 'Fat', value: 0 },
          { key: 'Saturated Fat', value: 0 },
          { key: 'Unsaturated Fat', value: 0 },
          { key: 'Carbohydrates', value: 0 },
          { key: 'Sugar', value: 0 },
          { key: 'Polyhydric Alcohols', value: 0 },
          { key: 'Starch', value: 0 },
          { key: 'Dietary Fiber', value: 0 },
          { key: 'Protein', value: 0 },
          { key: 'Salt', value: 0 },
        ],
      },
      {
        lang: 'de',
        val: [
          { key: 'Energie', value: 0 },
          { key: 'Fett', value: 0 },
          { key: 'Gesättigte Fettsäuren', value: 0 },
          { key: 'Ungesättigte Fettsäuren', value: 0 },
          { key: 'Kohlenhydrate', value: 0 },
          { key: 'Zucker', value: 0 },
          { key: 'Mehrwertige Alkohole', value: 0 },
          { key: 'Stärke', value: 0 },
          { key: 'Ballaststoffe', value: 0 },
          { key: 'Proteine', value: 0 },
          { key: 'Salz', value: 0 },
        ],
      },
    ],
  },
  {
    feId: 'ProductInfoCustomFields',
    desc: [
      { lang: 'en', val: 'Custom Fields' },
      { lang: 'de', val: 'Custom Fields' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ServingUnit',
    desc: [
      { lang: 'en', val: 'Serving Unit' },
      { lang: 'de', val: 'Serviereinheit' },
    ],
    value: [
      { lang: 'en', val: ['g'] },
      { lang: 'de', val: ['g'] },
    ],
  },
  {
    feId: 'ReferenceQuantity',
    desc: [
      { lang: 'en', val: 'Reference Quantity' },
      { lang: 'de', val: 'Reference Quantität' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ServingSize',
    desc: [
      { lang: 'en', val: 'Serving Size' },
      { lang: 'de', val: 'Serviergröße' },
    ],
    value: [
      { lang: 'en', val: ['100'] },
      { lang: 'de', val: ['100'] },
    ],
  },
  {
    feId: 'EnableServingSize',
    desc: [
      { lang: 'en', val: 'Enable Serving Size' },
      { lang: 'de', val: 'Enable Serving Size' },
    ],
    value: [
      { lang: 'en', val: ['X'] },
      { lang: 'de', val: ['X'] },
    ],
  },
  {
    feId: 'WidgetHeadline_Ingredients',
    desc: [
      { lang: 'en', val: 'Ingredients & Allergens' },
      { lang: 'de', val: 'Inhaltsstoffe & Allergene' },
    ],
    value: [
      { lang: 'en', val: ['Ingredients & Allergens'] },
      { lang: 'de', val: ['Inhaltsstoffe & Allergene'] },
    ],
  },
  {
    feId: 'ProductInfoIngredients',
    desc: [
      { lang: 'en', val: 'Ingredients' },
      { lang: 'de', val: 'Inhaltsstoffe' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ProductInfoAllergens',
    desc: [
      { lang: 'en', val: 'Allergens' },
      { lang: 'de', val: 'Allergene' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ProductInfoNotesAndWarnings',
    desc: [
      { lang: 'en', val: 'Notes and Warnings' },
      { lang: 'de', val: 'Notizen and Warnungen' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'WidgetHeadline_Distributor',
    desc: [
      { lang: 'en', val: 'Distributor' },
      { lang: 'de', val: 'Distributor' },
    ],
    value: [
      { lang: 'en', val: ['Distributor'] },
      { lang: 'de', val: ['Distributor'] },
    ],
  },
  {
    feId: 'ProductInfoResponsibleDistributor',
    desc: [
      { lang: 'en', val: 'Responsible Distributor' },
      { lang: 'de', val: 'Verantwortlicher Distributor' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'PostalCode',
    desc: [
      { lang: 'en', val: 'Postal Code' },
      { lang: 'de', val: 'Postleitzahl' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'City',
    desc: [
      { lang: 'en', val: 'City' },
      { lang: 'de', val: 'Stadt' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'Street',
    desc: [
      { lang: 'en', val: 'Street' },
      { lang: 'de', val: 'Straße' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'Link',
    desc: [
      { lang: 'en', val: 'Link' },
      { lang: 'de', val: 'Link' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'Email',
    desc: [
      { lang: 'en', val: 'Email' },
      { lang: 'de', val: 'Email' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ProducedIn',
    desc: [
      { lang: 'en', val: 'Produced in' },
      { lang: 'de', val: 'Hergestellt in' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'WidgetHeadline_Storage',
    desc: [
      { lang: 'en', val: 'Storage Instructions' },
      { lang: 'de', val: 'Aufbewahrungshinweise' },
    ],
    value: [
      { lang: 'en', val: ['Storage Instructions'] },
      { lang: 'de', val: ['Aufbewahrungshinweise'] },
    ],
  },
  {
    feId: 'ProductInfoStorageFields',
    desc: [
      { lang: 'en', val: 'Storage Fields' },
      { lang: 'de', val: 'Aufbewahrungsfelder' },
    ],
    value: [
      {
        lang: 'en',
        val: [
          { key: 'Temperature', value: '' },
          { key: 'Humidity', value: '' },
        ],
      },
      {
        lang: 'de',
        val: [
          { key: 'Temperatur', value: '' },
          { key: 'Feuchtigkeit', value: '' },
        ],
      },
    ],
  },
];
