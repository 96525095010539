//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const FooterWidget: IWidget = {
  id: uuid(),
  name: 'FooterWidget',
  order: 0,
  category: 'Required',
  open: false,
  label: {
    id: 'footer',
    name: 'Footer',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'WebsiteURL',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('WebsiteURL'),
          value: getValue('WebsiteURL'),
        },
        '2': {
          feId: 'ShowWebsiteURL',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ShowWebsiteURL'),
          value: getValue('ShowWebsiteURL'),
        },
        '3': {
          feId: 'XURL',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('XURL'),
          value: getValue('XURL'),
        },
        '4': {
          feId: 'ShowXURL',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ShowXURL'),
          value: getValue('ShowXURL'),
        },
        '5': {
          feId: 'InstagramURL',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('InstagramURL'),
          value: getValue('InstagramURL'),
        },
        '6': {
          feId: 'ShowInstagramURL',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ShowInstagramURL'),
          value: getValue('ShowInstagramURL'),
        },
        '7': {
          feId: 'FacebookURL',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('FacebookURL'),
          value: getValue('FacebookURL'),
        },
        '8': {
          feId: 'ShowFacebookURL',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('ShowFacebookURL'),
          value: getValue('ShowFacebookURL'),
        },
        '9': {
          feId: 'WebsiteName',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('WebsiteName'),
          value: getValue('WebsiteName'),
        },
        '10': {
          feId: 'Imprint',
          change: false,
          translate: false,
          data: false,
          valueType: 'value',
          name: getDesc('Imprint'),
          value: getValue('Imprint'),
        },
      },
    },
  ],
  style: {
    objects: {
      '1': {
        name: 'Orientation',
        value: ['center'],
      },
      '2': {
        name: 'Show Rights Reserved',
        value: ['X'],
      },
      '3': {
        name: 'Logo',
        value: [''],
      },
      '4': {
        name: 'Show Logo',
        value: [''],
      },
      '5': {
        name: 'Logo Filename',
        value: [''],
      },
    },
  },
};
