import { ILangWidget } from 'src/types/product';

export const LangProductHead: ILangWidget = [
  {
    feId: 'ProductHeadBrandName',
    desc: [
      { lang: 'en', val: 'Brand Name' },
      { lang: 'de', val: 'Markenname' },
    ],
    value: [
      { lang: 'en', val: ['Brand Name'] },
      { lang: 'de', val: ['Markenname'] },
    ],
  },
  {
    feId: 'ProductHeadProductName',
    desc: [
      { lang: 'en', val: 'Product Name' },
      { lang: 'de', val: 'Produktname' },
    ],
    value: [
      { lang: 'en', val: ['Product Name'] },
      { lang: 'de', val: ['Produktname'] },
    ],
  },
  {
    feId: 'ShowProductTags',
    desc: [
      { lang: 'en', val: 'Show Product Tags' },
      { lang: 'de', val: 'Produkt-Tags anzeigen' },
    ],
    value: [
      { lang: 'en', val: ['X'] },
      { lang: 'de', val: ['X'] },
    ],
  },
  {
    feId: 'ProductHeadProductTags',
    desc: [
      { lang: 'en', val: 'Product Tags' },
      { lang: 'de', val: 'Produkt-Tags' },
    ],
    value: [
      { lang: 'en', val: ['Tag1', 'Tag2'] },
      { lang: 'de', val: ['Tag1', 'Tag2'] },
    ],
  },
  {
    feId: 'ProductPicture',
    desc: [
      { lang: 'en', val: 'Product Picture' },
      { lang: 'de', val: 'Produktbild' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ProductBanner',
    desc: [
      { lang: 'en', val: 'Product Banner' },
      { lang: 'de', val: 'Produktbanner' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ProductFilename',
    desc: [
      { lang: 'en', val: 'Product Filename' },
      { lang: 'de', val: 'Produktdateiname' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'BannerFilename',
    desc: [
      { lang: 'en', val: 'Banner Filename' },
      { lang: 'de', val: 'Bannerdateiname' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ShowProduct',
    desc: [
      { lang: 'en', val: 'Show Product' },
      { lang: 'de', val: 'Produkt anzeigen' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
  {
    feId: 'ShowBanner',
    desc: [
      { lang: 'en', val: 'Show Banner' },
      { lang: 'de', val: 'Banner anzeigen' },
    ],
    value: [
      { lang: 'en', val: [] },
      { lang: 'de', val: [] },
    ],
  },
];