//
import { v4 as uuid } from 'uuid';
import { IWidget } from 'src/types/product';
import { getDesc, getValue } from '../_langWidgets';

// ----------------------------------------------------------------------

export const Heading: IWidget = {
  id: uuid(),
  name: 'Heading',
  order: 0,
  category: 'Text',
  open: false,
  label: {
    id: 'heading',
    name: 'Heading',
    color: 'black',
  },
  childs: [
    {
      id: 'Head',
      open: true,
      show: true,
      attributes: {
        '1': {
          feId: 'HeadingText',
          change: true,
          translate: true,
          data: true,
          valueType: 'value',
          name: getDesc('HeadingText'),
          value: getValue('HeadingText'),
        },
      },
    },
  ],
  style: {
    objects: {
      '1': {
        name: 'Orientation',
        value: ['center'],
      },
      '2': {
        name: 'Weight',
        value: [''],
      },
      '3': {
        name: 'Color',
        value: [''],
      },
      '4': {
        name: 'Size',
        value: [''],
      },
    },
  },
};
