// ----------------------------------------------------------------------

export const _nutriReference = [
  [   // EN
    {
      key: 'Energy',
      value: 8400,
    },
    {
      key: 'Fat',
      value: 70,
    },
    {
      key: 'Saturated Fat',
      value: 20,
    },
    {
      key: 'Unsaturated Fat',
      value: 50,
    },
    {
      key: 'Carbohydrates',
      value: 260,
    },
    {
      key: 'Sugar',
      value: 90,
    },
    {
      key: 'Starch',
      value: 310,
    },
    {
      key: 'Polyhydric Alcohols',
      value: 20,
    },
    {
      key: 'Dietary Fiber',
      value: 100,
    },
    {
      key: 'Protein',
      value: 50,
    },
    {
      key: 'Salt',
      value: 6,
    },
  ],
  [     // DE
    {
      key: 'Energie',
      value: 8400,
    },
    {
      key: 'Fett',
      value: 70,
    },
    {
      key: 'Gesättigte Fettsäuren',
      value: 20,
    },
    {
      key: 'Ungesättigte Fettsäuren',
      value: 50,
    },
    {
      key: 'Kohlenhydrate',
      value: 260,
    },
    {
      key: 'Zucker',
      value: 90,
    },
    {
      key: 'Stärke',
      value: 310,
    },
    {
      key: 'Mehrwertige Alkohole',
      value: 20,
    },
    {
      key: 'Ballaststoffe',
      value: 100,
    },
    {
      key: 'Proteine',
      value: 50,
    },
    {
      key: 'Salz',
      value: 6,
    },
  
  ]
];
