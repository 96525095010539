// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgGradient } from 'src/theme/css';
// components
import Logo from 'src/components/logo';
import LogoBigSlogan from 'src/assets/logos/cirelis-big-slogan';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
  isRegisterPage?: boolean;
};

export default function AuthClassicLayout({ children, image, title, isRegisterPage }: Props) {
  const theme = useTheme();

  const upMd = useResponsive('up', 'md');

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
    />
  );

  const renderContent = (
    <Stack
      sx={{
        justifyContent: 'center',
        width: 1,
        mx: 'auto',
        maxWidth: 480,
        px: { xs: 2, md: 8 },
        pt: 8,
        pb: 8,
        overflowY: 'auto',
        boxSizing: 'border-box',
      }}
    >
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      spacing={10}
      sx={{
        ...bgGradient({
          color: alpha(
            theme.palette.background.default,
            theme.palette.mode === 'light' ? 0.88 : 0.94
          ),
          imgUrl: '/assets/background/overlay_2.jpg',
        }),
      }}
    >
      <Stack direction="column" alignItems="center" spacing={2}>
        <Typography
          variant="h4"
          sx={{
            maxWidth: 480,
            textAlign: 'center',
            mb: 2,
            color: alpha(theme.palette.text.primary, 0.8),
          }}
        >
          {isRegisterPage ? (
            <>
              Manage all your
              <br />
              <Typography
                component="span"
                variant="h3"
                sx={{
                  fontWeight: 'bold',
                  fontStyle: 'italic',
                  display: 'block',
                  my: 1,
                  fontFamily: 'Roboto',
                  color: alpha(theme.palette.primary.main, 0.8),
                }}
              >
                Digital Product Labels
                <br />
              </Typography>
              with...
            </>
          ) : (
            'Hi, Welcome back🎉'
          )}
        </Typography>

        <LogoBigSlogan sx={{ maxWidth: 720, width: '320px', height: 'auto' }} />
      </Stack>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderLogo}

      {upMd && renderSection}

      {renderContent}
    </Stack>
  );
}
